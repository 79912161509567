//rename to functional values; do not use colored names!
$color-charcoal-grey: #32393e;
$color-rouge: #ba1e2b;
$color-deep-red: #990a15;
$color-dusty-orange: #e6682b;
$color-steel: #e6682b;
$color-pale-grey: #f1f3f4;
$color-silver: #d6d7d8;
$color-sepia: #8b572a;
$color-mocha: #a27955;
$color-pale-brown: #b59477;
$color-mid-green: #539b3d;
$color-dark-grass-green: #1f8500;
$color-deep-green: #196c00;
$color-milk-chocolate: #754317;
$color-blue: #4990e2;

$color-blue-dropshadow: rgba(73,144,226,0.4);
$color-blue-dropshadow-active: rgba(23,61,94,0.6);

$color-blue-hover: rgba(73,144,226,0.2);
$color-blue-selected: rgba(73,144,226,0.9);

$color-sepia-hover: rgba(139,87,42,0.2);
$color-sepia-selected: rgba(139,87,42,0.9);

$color-steel-hover: rgba(230,104,43,0.2);
$color-steel-selected: rgba(230,104,43,0.9);

$color-high-hover : lighten($color-sepia-hover, 30%);
$color-high-selected : $color-sepia-selected;

$color-mid-hover : lighten($color-steel-hover, 30%);
$color-mid-selected : $color-steel-selected;

$color-low-hover : lighten($color-blue-hover, 30%);
$color-low-selected : $color-blue-selected;